import React from 'react';

const Presentation = () => {
  return (
    <div>
      <h1>Welcome to My Presentation</h1>
      <div>
        <h2>Slide 1</h2>
        <p>This is the content of slide 1.</p>
      </div>
      <div>
        <h2>Slide 2</h2>
        <p>This is the content of slide 2.</p>
      </div>
      <div>
        <h2>Slide 3</h2>
        <p>This is the content of slide 3.</p>
      </div>
    </div>
  );
};

export default Presentation;
